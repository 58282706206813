<template>
  <div
    class="relative z-20 bg-white"
    :class="[
      mobileMenuIsOpen ? 'pt-14' : '',
      route.name === 'prototype' ? 'flex flex-col flex-grow flex-shrink-0 h-screen' : 'flex flex-col min-h-screen [&>div:not(.exclude)]:w-full'
    ]"
    @click="closeProfileMenu"
    @keyup.esc="closeProfileMenu"
  >
    <Modal
      :persistent="true"
      :is-open="connectModalState.matches('connecting')"
      :hide-action-buttons="true"
      :centered="true"
      :title="connector?.id === 'crossMint' ? 'Sign into Crossmint' : 'Connect your wallet'"
      :subtitle="connector?.id === 'crossMint' ? 'Sign into Crossmint with your email or social accounts to complete your login on remx.' : 'Connect your wallet to complete your login on remx.'"
    >
      <Body size="sm">
        Need help? <a
          class="underline cursor-pointer"
          @click="troubleshootWallet"
        > Check out our FAQ</a>
      </Body>
    </Modal>

    <Suspense>
      <GlobalHeader
        v-if="!hideGlobalHeader"
        :mobile-menu-is-open="mobileMenuIsOpen"
        :profile-menu-is-open="profileMenuIsOpen"
        :is-dark="route.matched.some(entry => entry.meta.darkNav)"
        :is-showroom="route.matched.some(entry => entry.meta.showroom)"
        :is-create-nav="route.matched.some(entry => entry.meta.createNav)"
        @open-mobile-menu="openMobileMenu"
        @close-mobile-menu="closeMobileMenu"
        @close-profile-menu="closeProfileMenu"
        @toggle-profile-menu="toggleProfileMenu"
        @slide-to-wait-list="navigateTo( route.matched.some(entry => entry.meta.isLegacy) ? 'home-legacy' : 'home', '#wait-list-email-form')"
      />
    </Suspense>

    <Snackbar />

    <section
      class="w-full flex pt-0"
      :class="[!hideGlobalHeader ? 'md:pt-8' : '']"
    >
      <div
        v-if="!hideGlobalHeader"
        class="hidden md:block w-0 md:w-[300px] lg:w-[350px] sticky top-0"
      >
        <GlobalSideNav />
      </div>

      <div class="w-full">
        <RouterView
          v-slot="{ Component }"
          class="relative z-0 grow flex flex-col mb-16 md:mb-0"
        >
          <transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-300 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            mode="out-in"
          >
            <Suspense>
              <slot>
                <component :is="Component" />
              </slot>
            </Suspense>
          </transition>
          <!-- <AccountActivity class="sticky bottom-5 md:bottom-10" /> -->
        </RouterView>
      </div>

      <GlobalMobileFooterNav v-if="isAuthenticated && !hideGlobalHeader" />
    </section>

    <ReloadPrompt />

    <Suspense v-if="route.name !== 'start-create-account'">
      <LoginModal />
    </Suspense>

    <Suspense>
      <XProfileModal
        v-if="!hasTwitterProfile"
        :is-open="isXModalOpen"
        @close="closeConnectXModal"
      />
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAccount as useWagmiAccount } from 'use-wagmi'
import { useGtag } from 'vue-gtag-next'
import { datadogRum } from '@datadog/browser-rum'

import useLogin from '#composables/use-login'
import useCreatePost from '#composables/use-create-post'
import { useLoginModal } from '#composables/use-login-modal'
import useAccount from '#composables/use-account'
import { useMutation } from '#composables/use-apollo'
import useConnectX from '#composables/use-connect-x'

import { UPDATE_ACCOUNT } from '#mutations/UpdateAccount'
import type { UpdateAccount, UpdateAccountVariables } from '#graphql/types/UpdateAccount'

import AccountActivity from '#components/global/account-activity.vue'
import Body from '#components/typography/body'
import Modal from '#components/modal'
import ReloadPrompt from '#components/reload-prompt'
import Snackbar from '#components/snackbar'
import GlobalHeader from '#components/global/global-header.vue'
import LoginModal from '#components/login-modal'
import XProfileModal from '#components/x-profile-modal/x-profile-modal.vue'
import GlobalSideNav from '#components/global/global-side-nav.vue'
import GlobalMobileFooterNav from '#components/global/global-mobile-footer-nav.vue'


const {
  hasFarcaster,
  accountNotifications,
  hasTwitterProfile,
} = useAccount()
const { connector } = useWagmiAccount()
const {
  account,
  accountId,
  isAuthenticated,
  reset: resetAuth,
} = useLogin()
const { state: connectModalState, send } = useLoginModal()
const router = useRouter()
const route = useRoute()
const { config } = useGtag()
const {
  isOpen: isXModalOpen,
  closeConnectXModal,
} = useConnectX()

const mobileMenuIsOpen = ref(false)
const profileMenuIsOpen = ref(false)
const hasFarcasterAccount = computed(() => hasFarcaster.value)
const showFarcasterAddToAccountAnnouncement = ref(false)
const showFarcasterShareProfileAnnouncement = ref(false)

const hideGlobalHeader = computed(() => route.matched.some(entry => entry.meta.hideGlobalHeader))

const openMobileMenu = () => mobileMenuIsOpen.value = true
const closeMobileMenu = () => mobileMenuIsOpen.value = false

const closeProfileMenu = () => profileMenuIsOpen.value = false
const toggleProfileMenu = () => profileMenuIsOpen.value = !profileMenuIsOpen.value

const navigateTo = (name: string, hash: string) => {
  router.push({ name, hash })
}

const troubleshootWallet = () => {
  resetAuth()
  closeMobileMenu()

  // @todo: this is a temporary workaround to get the page to refresh when
  // navigating to the FAQ page from the connect modal if you try to navigate
  // before you are fully signed in, or else  the connector will still be
  // running, and will not allow you to log in until you manually refresh the
  // page.
  const faqUrl = '/faq#accounts'

  if (route.name === 'faq') {
    window.location.reload()
  } else {
    window.location.href = faqUrl
  }

  send('CLOSE')
}

/**
 * Mutations.
 */

// Update Account
const {
  mutate: updateAccount,
  loading: updateAccountLoading,
  onResult: updateAccountOnResult,
  onError: updateAccountOnError,
} = useMutation<UpdateAccount, UpdateAccountVariables>(UPDATE_ACCOUNT)

updateAccountOnError((error) => {
  console.error('Error updating account:', error)
})

// When a user gets unauthenticated, make sure they're not on a protected route.
watch(
  isAuthenticated,
  (newAuth) => {
    if (!newAuth && route.meta.requiresAuthentication) {
      router.push({ name: 'home' } )
    }
    if (newAuth && account?.value.referrerCode && !account?.value.referralCode) {
      if (!localStorage.getItem('invite2earn')) {
        router.push({ name: 'invite2earn' })
        localStorage.setItem('invite2earn', 'true')
      }
    }
  }
)

// update ctag config when account changes
watch(
  accountId,
  (newId) => {
    config({
      user_id: newId,
    })

    if (import.meta.env.VITE_DD_ENVIRONMENT && import.meta.env.VITE_DD_CLIENT_TOKEN) {
      if (newId) {
        datadogRum.setUser({ id: newId })
      } else {
        datadogRum.clearUser()
      }
    }
  }
)
watch(() => [hasFarcasterAccount.value, accountNotifications.value], ([hasFarcasterAccount, accountNotifications]) => {
  showFarcasterAddToAccountAnnouncement.value = !hasFarcasterAccount && (accountNotifications?.farcaster && !accountNotifications.farcaster.addToAccountDismissed)

  showFarcasterShareProfileAnnouncement.value = hasFarcasterAccount && (accountNotifications?.farcaster && !accountNotifications.farcaster.shareProfileDismissed)
}, { immediate: true })
</script>

<style>
/* crossming pay button doesn't seem to read its own props, so this hack is necessary */
div#__crossmint-overlay__ {
  display: none;
}
</style>
